import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, List, ChildList } from "../components/Core";

const TermsConditions = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Title variant="hero" className="text-center">
                  Classified policies
                </Title>

                <Box mt={["40px", null, "50px"]}>
                    <Text variant="small" mb="5px">
                        Kesto prohibits the listing of any item that is set forth in the list of prohibited items. If a user lists a prohibited item, it will be deemed to be a violation of our Terms of Service regardless of whether the User acted intentionally or not. If Kesto determines that a listing is in violation or is otherwise inappropriate Kesto may, at its discretion, remove the listing and cancel any related transactions up to and including termination or suspension.
                    </Text>
                    <Text variant="small" mb="5px">
                        Kesto may not be used in connection with any product, service, transaction or activity that in general:
                    </Text>
                    <List>
                        <li>
                            Violates any law or government regulation, or promotes or facilitates such action by third parties
                        </li>
                        <li>
                            Is fraudulent, deceptive, unfair or predatory
                        </li>
                        <li>
                            Causes or threatens to damage Kesto’s reputation
                        </li>
                        <li>
                            Violates the terms of any bank, card or electronic funds transfer network
                        </li>
                        <li>
                            Results in or creates a significant risk of chargebacks, penalties, damages or other harm or liability
                        </li>
                    </List>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                    <Text variant="small" mb="5px">
                        The list of Prohibited Items may be updated from time to time at Kesto's discretion. Examples of items that fall under certain categories may also be expanded to provide better communication and context to our users.
                    </Text>
                    <Text variant="small" mb="5px">
                        Kesto may not be used to advertise or list any of the following products or services:
                    </Text>
                    <List>
                        <li>
                            Anything illegal
                        </li>
                        <li>
                            Drugs including:
                        </li>
                    </List>

                    <ChildList>
                        <li>
                            Illegal drugs or narcotics
                        </li>
                        <li>
                            Prescription medicines or devices, pharmaceuticals, or behind the counter drugs
                        </li>
                        <li>
                            Products, tools, or services specifically offered or intended to be used to create or use drugs
                        </li>
                        <li>
                            Grow ingredients for drugs (such as seeds for cannabis plants)
                        </li>
                    </ChildList>

                    <List>
                        <li>
                            FDA restricted items such as food, homemade food, food supplements, vitamins, diet products, muscle enhancers, home remedies and homemade cosmetics (any ingestibles):
                        </li>
                    </List>
                    <ChildList>
                        <li>
                            Listings or items descriptions that offer miracle cures such as “anti-aging”, “cancer protection”
                        </li>
                        <li>
                            Listings that make false health claims or misuse terms (such as “virus”, "epidemic")
                        </li>
                        <li>
                            Items that contain cannabidiol (CBD)
                        </li>
                    </ChildList>

                    <List>
                        <li>
                            Inflated prices where listings attempt to profit from tragedies and disasters (such as “paradise fire”, “coronavirus outbreak” etc)
                        </li>
                        <li>
                            Stolen goods:
                        </li>
                    </List>
                    <ChildList>
                        <li>
                            Note: If a purchased item is reported as stolen, a demand for return may be received from the victim or another party, and the item may be confiscated according to the regulations of the Code of Criminal Procedure (Act no. 131 of 1948).
                        </li>
                    </ChildList>

                    
                    <List>
                        <li>
                            Counterfeit goods or goods infringing on a third party's intellectual property rights:
                        </li>
                    </List>
                    <ChildList>
                        <li>
                            Listings of non-brand, non-genuine, imitation, fake, or replica
                        </li>
                        <li>
                            Items in violation of a copyright, including handmade, or other items with copyrighted characters, brand logos, etc.
                        </li>
                        <li>
                            Note: For brand-name products, serial numbers or receipts must be available when listing the item.
                        </li>
                    </ChildList>

                    
                    <List>
                        <li>
                            Weapons including:
                        </li>
                    </List>
                    <ChildList>
                        <li>
                            Firearms and firearm parts; including airsoft and bb guns
                        </li>
                        <li>
                            Knives such as switchblades, butterfly knives, knives that are concealed or hidden within other objects, or are made of materials that cannot be detected with a metal detector
                        </li>
                        <li>
                            Explosives or military ordinance
                        </li>
                        <li>
                            Self defense weapons
                        </li>
                    </ChildList>


                    <List>
                        <li>
                            Alcohol
                        </li>
                        <li>
                            Tobacco products:
                        </li>
                    </List>
                    <ChildList>
                        <li>
                            Cigarettes
                        </li>
                        <li>
                            E-cigarettes, e-hookahs, or other vaporizing instruments that contain nicotine or are associated with tobacco or nicotine usage
                        </li>
                        <li>
                            Vaporizing liquid without nicotine
                        </li>
                    </ChildList>
                    
                    <List>
                        <li>
                            Items used for identity theft (such as account credentials, user login information, and/or account access codes)
                        </li>
                        <li>
                            Items not in your possession
                        </li>
                    </List>
                    <ChildList>
                        <li>
                            Advertisements or listings for objects being sought
                        </li>
                        <li>
                            Coupons to purchase products
                        </li>
                    </ChildList>

                    <List>
                        <li>
                            Items that are a safety hazard
                        </li>
                    </List>
                    <ChildList>
                        <li>
                            Note: Flammable or combustible liquids and any other items that require special mailing or handling instructions must be sent using the ship on your own option.
                        </li>
                    </ChildList>


                    <List>
                        <li>
                            Products designed to circumvent copyright protection techniques or otherwise facilitate the unlicensed use of copyright materials (such as "mod chips" to break the encryption on game computers to allow the playing of unlicensed game copies)
                        </li>
                        <li>
                            Age restricted products or products that require a legal approval
                        </li>
                        <li>
                            Gambling, including using this service for raffles and mystery purchases
                        </li>
                        <li>
                            Financial products and services such as:
                        </li>
                    </List>
                    <ChildList>
                        <li>
                            Bonds, securities, warranties and insurance
                        </li>
                        <li>
                            All currency in circulation of any denomination except collectibles
                        </li>
                        <li>
                            Precious metals including bullion or bars
                        </li>
                        <li>
                            Gift cards or prepaid cards
                        </li>
                    </ChildList>


                    <List>
                        <li>
                            Live animals or trafficked materials (such as ivory or shark fins)
                        </li>
                        <li>
                            Any item that contains a computer virus, malware, or spyware
                        </li>
                        <li>
                            Digital items - any items where the order is fulfilled electronically or requires a download such as:
                        </li>
                    </List>
                    <ChildList>
                        <li>
                            Ebooks, PDF files, user generated content (UGC) or items for online games
                        </li>
                    </ChildList>


                    <List>
                        <li>
                            Humans, human body parts, organs, cells, blood, body fluids, and items that are soiled with human materials such as used underwear
                        </li>
                        <li>
                            Explicit items:
                        </li>
                    </List>
                    <ChildList>
                        <li>
                            Sexually explicit items such as sex toys and fetish items
                        </li>
                    </ChildList>


                    <List>
                        <li>
                            Offensive listings:
                        </li>
                    </List>
                    <ChildList>
                        <li>
                            Items, listings, photos or content that promote or glorify hatred, violence, racism or discrimination aren't allowed (determined at our discretion).
                        </li>
                    </ChildList>


                    <Text variant="small" mb="10px">
                        Note: Any item may be removed at the discretion of the company.
                    </Text>

                    <Text variant="small" mb="10px">
                        Updated:
                    </Text>
                    <List>
                        <li>
                            April 30, 2021
                        </li>
                    </List>
                </Box>

              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
